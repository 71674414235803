<template>
    <div class="pb-10 h-100 general-profile-settings">
        <div class="pt-2 pr-2">
            <!-- <div class="mt-4 mb-3 d-flex align-items-center w-100" style="position: relative;">
                <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                <input type="text" placeholder="Search Settings" class="search-input-field">
            </div> -->
            <div class="settings-container">
                <div class="settings-left-sec">
                    <ul class="settings-left-sec-list">
                        <li class="fw-600 fs-14 pointer d-flex align-items-center"
                            :class="{'activeOption':$route.path == '/settings/general'}" @click="selectTab('general')">
                            <img class="mr-3"
                                :src="$route.path == '/settings/general' ? 'https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/home-tab-icon-blue.png' : '/static/images/home-tab-icon.svg'"
                                width="25px" height="25px" />
                            General
                        </li>
                        <li class="fw-600 fs-14 pointer d-flex align-items-center"
                            :class="{'activeOption':$route.path == '/settings/profile'}" @click="selectTab('profile')">
                            <img class="mr-3"
                                :src="$route.path == '/settings/profile' ? 'https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/profile-tab-icon-blue.png' : '/static/images/profile-tab-icon.svg'"
                                width="25px" height="28px" />
                            Profile
                        </li>
                    </ul>
                </div>
                <div class="pt-5 w-100" style="min-width: 550px;">
                    <div v-if="$route.path == '/settings/general'">
                        <div class="create-settings-header d-flex align-items-center justify-content-between">
                            <span class="fs-14 text-white">Manage your Account </span>
                            <span class="pointer" @click="showManageAccount">
                                <i v-if="accountTab" class="icon-chevron-double-up text-white"
                                    style="font-size: 20px"></i>
                                <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                            </span>
                        </div>
                        <div v-if="accountTab" >
                            <div class="account-box mx-5 my-10" :class="{'account-box-disabled': two_step_enable}">
                                <div class="px-5" style="min-width: 425px; width: 425px;">
                                    <div class="d-flex align-items-center py-5">
                                        <h2 class="fs-16 fw-600 mb-0 mr-15" style="color: #3f3f3f;" :class="{'disabled-text':two_step_enable}">Enable OTP login
                                        </h2>
                                        <el-switch v-model="is_otp_login" @change="changeLoginAuth" active-color="#2cc36a" inactive-color="#bcb8b8">
                                        </el-switch><!-- :disabled="two_step_enable" -->
                                    </div>
                                </div>
                            </div>
                            <div class="account-box mx-5 my-10 py-5" :class="{'account-box-disabled': is_otp_login}">
                                <div class="px-5" style="min-width: 425px; width: 425px;">
                                    <!-- <h1 class="text-secondary fs-16 fw-600">Manage your Account </h1> -->
                                    <div class="d-flex align-items-center">
                                        <h2 class="fs-16 fw-600 mb-0 mr-15" style="color: #3f3f3f;" :class="{'disabled-text': is_otp_login}">Enable Two-step Verification
                                        </h2>
                                        <el-switch v-model="two_step_enable" @change="changeTwoFactoreAuth" active-color="#2cc36a" inactive-color="#bcb8b8">
                                        </el-switch><!-- :disabled="is_otp_login" -->
                                    </div>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'" class="w-100 mt-4">
                                        <span class="input-label" :class="{'disabled-text': is_otp_login}">Set your Default Company</span>
                                        <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                            <multiselect class="diginew-multiselect" :show-labels="false" name="organization" :disabled="is_otp_login" :class="{'diginew-multiselects': is_otp_login}" 
                                                :searchable="true" label='organization_name' @select="changeOrg" :loading="org_loading" @open="getListViewData" :options="organization_options" v-model="organization"
                                                placeholder="Select Organization">
                                                <template slot="noOptions">
                                                    <div class="text-secondary text-center fw-600">List is Empty</div>
                                                </template>
                                                <template slot="noResult">
                                                    <div class="text-secondary text-center fw-600">No Results Found</div>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div v-if="organization_comp.length > 0">
                                            <div v-for="(org_comp, org_comp_index) in organization_comp" :key="org_comp_index">
                                                <div class="form-check form-check-inline ml-5 mt-4">
                                                    <label class="form-check-label" :class="{'form-check-label-disabled':is_otp_login, }">
                                                        <input class="form-check-input" :class="{'disabled-text': is_otp_login}" :disabled="is_otp_login" type="radio" @change="changeComp(org_comp)" :checked="org_comp.checked" name="company">
                                                        <span class="radio-icon"></span>
                                                        <span class="form-check-description mt-1px" :class="{'disabled-text': is_otp_login}">{{org_comp.company_name}}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 mt-4 px-5" style="min-width: 425px; width: 425px !important;">
                                    <span class="input-label" :class="{'disabled-text': is_otp_login}">Set Default Timezone</span>
                                    <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                        <multiselect class="diginew-multiselect" :show-labels="false" name="currency" :disabled="is_otp_login" :class="{'diginew-multiselects': is_otp_login}" 
                                            :searchable="true" label='value' @select="changeTimeZone" :options="time_zone_options" v-model="time_zone"
                                            placeholder="Select Time Zone">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="w-100 mt-4 px-5" style="min-width: 425px; width: 425px !important;">
                                    <span class="input-label" :class="{'disabled-text': is_otp_login}">Set Default Language</span>
                                    <div class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                        <multiselect class="diginew-multiselect" :show-labels="false" name="language" :disabled="is_otp_login"
                                            :searchable="true" @select="changeLanguage" :options="language_options" v-model="language"
                                            placeholder="Select Language" :class="{'diginew-multiselects': is_otp_login}" >
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <!-- <hr> -->
                                <div class="mt-10 px-5">
                                    <button type="button" :disabled="is_otp_login" class="btn btn-new-primary" @click="changePassword">Change Password</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="create-settings-container w-100 ml-1">
                                <div class="mt-0">
                                    <div class="create-settings-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white">Contact Type </span>
                                        <span class="pointer" @click="showContactInformation">
                                            <i v-if="contactTab" class="icon-chevron-double-up text-white"
                                                style="font-size: 20px"></i>
                                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div class="pt-5 px-4" v-if="contactTab">
                                        <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                            </div>
                                            <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!ajax_call_in_progress && contact_type_data.length == 0">
                                                <span>Contact Types Not Found</span>
                                            </div>
                                        <div v-if="!ajax_call_in_progress">
                                            <!-- <h1 class="text-secondary fs-14 md-5">Contact Type Options</h1> -->
                                            <div class="row w-100" v-if="!loader_data_ajax">
                                                <div v-for="(contact, contact_index) in contact_type_data" :key="contact_index+'contact_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'admin'" @change="contactTypeCheckbox($event, contact.id)" class="mb-0" name="contact_type1"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4 text-capitalize">
                                                            <span :title="contact.value" class="pl-3 fs-14 text-truncate">{{contact.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="add_new_contact_type" class="row w-100">
                                                <div class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100 position-relative">
                                                        <el-checkbox class="mb-0" name="contact_type2"></el-checkbox>
                                                        <input class="input-field w-100 ml-4" maxlength="100" style="padding-left: 21px !important;" type="text" v-model="new_contact_type" placeholder="Enter Contact Type" />
                                                        <span style="position: absolute;top: 40px;right: 60px;">
                                                            {{100 - (new_contact_type).length}}/100
                                                        </span>
                                                        <div class="d-flex justify-content-end align-items-center ml-2" >
                                                            <div class="pointer" @click="removeEntry('contact_type_option')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateEntry('contact_type_option')" :class="{'disabled' : new_contact_type == ''}">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-10 mr-8 text-right">
                                                <button @click="addNew('contact_type_option')"
                                                    class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                                    style="background-color: transparent; outline: 0 none !important;">+ Add New
                                                    Field</button>
                                                <button  @click="deleteInput('contact_type_option')" v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'admin' && contact_type_data.length > 0"
                                                    class="btn btn-new-danger mt-4" :disabled="contact_type_selected.length < 1">
                                                    <i class="icon icon-minus fs-14"></i>
                                                    Delete1
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="create-settings-container w-100 ml-1" id="industry_type">
                                <div class="mt-9 pb-10" >
                                    <div class="create-settings-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white">Industry Type </span>
                                        <span class="pointer" @click="showIndustryInformation">
                                            <i v-if="industryTab" class="icon-chevron-double-up text-white"
                                                style="font-size: 20px"></i>
                                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div class="pt-5 px-4" v-if="industryTab">
                                        <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!ajax_call_in_progress && industry_type_data.length == 0">
                                            <span>Contact Types Not Found</span>
                                        </div>
                                        <div v-if="!ajax_call_in_progress">
                                            <!-- <h1 class="text-secondary fs-14 md-5">Industry Type Options</h1> -->
                                            <div class="row w-100" v-if="!loader_data_ajax">
                                                <div v-for="(industry, industry_index) in industry_type_data" :key="industry_index+'industry_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'admin'" @change="industryTypeCheckbox($event, industry.id)" class="mb-0" :name="'address_id1'"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4 text-capitalize">
                                                            <span :title="industry.value" class="pl-3 fs-14 text-truncate">{{industry.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="add_new_industry_type" class="row w-100">
                                                <div class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100 position-relative">
                                                        <el-checkbox class="mb-0" name="contact_type2"></el-checkbox>
                                                        <input class="input-field w-100 ml-4" maxlength="100" style="padding-left: 21px !important;" type="text" v-model="new_industry_type" placeholder="Enter Industry Type" />
                                                        <span style="position: absolute;top: 40px;right: 60px;">
                                                            {{100 - (new_industry_type).length}}/100
                                                        </span>
                                                        <div class="d-flex justify-content-end align-items-center ml-2" >
                                                            <div class="pointer" @click="removeEntry('industry_type_option')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateEntry('industry_type_option')" :class="{'disabled' : new_industry_type == ''}">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-10 mr-8 text-right">
                                                <button @click="addNew('industry_type_option')"
                                                    class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                                    style="background-color: transparent; outline: 0 none !important;">+ Add New
                                                    Field</button>
                                                <button @click="deleteInput('industry_type_option')" v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'admin' && industry_type_data.length > 0"
                                                    class="btn btn-new-danger mt-4" :disabled="industry_type_selected.length < 1">
                                                    <i class="icon icon-minus fs-14"></i>
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="$route.path == '/settings/profile'" class="pl-10">
                        <div class="pr-10 pb-10">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="w-100 mt-4">
                                        <multiselect class="diginew-multiselect" :show-labels="false" name="select_profile_section"
                                            :searchable="true" :options="profile_section_options" v-model="profile_section_selected"
                                            placeholder="Select" @select="profileSetionSelected">
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="pt-5" v-if="profile_section_selected == 'Basic Information'">
                                <div>
                                    <h1 class="text-secondary fs-14 md-5">Tax ID</h1>
                                    <div class="row w-100">
                                        <div class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100">
                                                <el-checkbox class="mb-0" :name="'tax_id1'"></el-checkbox>
                                                <div class="input-field d-flex align-items-center w-100 ml-4">
                                                    <span class="pl-3 fs-14">SSN</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100">
                                                <el-checkbox class="mb-0" :name="'tax_id2'"></el-checkbox>
                                                <div class="input-field d-flex align-items-center w-100 ml-4">
                                                    <span class="pl-3 fs-14">SSN</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_taxid_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4" style="padding-left: 21px !important;" type="text" v-model="new_taxid_type" placeholder="Enter Tax Id" />
                                                <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: auto; top: auto;right: -50px;" >
                                                    <div class="pointer" @click="removeEntry('taxid_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateEntry('taxid_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('taxid_type_option')" :disabled="add_new_taxid_type" class="text-secondary fs-14 pointer text-right d-block ml-auto" style="background-color: transparent; outline: 0 none !important;">+ Add New Field</button>
                                        <button @click="deleteInput('taxid_type_option')" class="btn btn-new-danger mt-4">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div> -->
                            <div class="pt-5" v-if="profile_section_selected == 'Address'">
                                <div>
                                    <h1 class="text-secondary fs-14 md-5">Address Options</h1>
                                    <div>
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && adddress_data.length == 0">
                                            <span>Address Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(addr, addr_index) in adddress_data" :key="addr_index+'addr_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="addressTypeCheckbox($event, addr.id)" :name="'address_id'+addr_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="addr.value" class="pl-3 fs-14 text-truncate">{{addr.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_address_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" type="text"
                                                    v-model="new_address_type" maxlength="100" placeholder="Enter Address Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_address_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('address_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer"
                                                        @click="updateEntry('address_type_option')" :class="{'disabled' : new_address_type == ''}">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('address_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('address_type_option')" v-if="adddress_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="address_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-5" v-if="profile_section_selected == 'Communication'">
                                <div>
                                    <h1 class="text-secondary fs-14 md-5">Contact Options</h1>
                                    <div>
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && phone_type_options.length == 0">
                                            <span>Contacts Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(options, phone_type_option_index) in phone_type_options" :key="phone_type_option_index+'phone_type_option_index'"
                                                    class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="communicationContactTypeCheckbox($event, options.id)" :name="'contact_checkbox'+phone_type_option_index">
                                                        </el-checkbox>
                                                        <div class="input-field d-flex align-items-center position-relative w-100 ml-4"
                                                            style="padding-left: 20px !important;">
                                                            <div class="d-flex align-items-center fs-14">
                                                                <!-- <img :src="options.image" /> -->
                                                                <span :title="options.value" class="pl-3 text-truncate">
                                                                    {{options.value}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_communication_contact_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_communication_contact_type"
                                                    placeholder="Enter Contact Option Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_communication_contact_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('communication_contact_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" :class="{'disabled' : new_communication_contact_type == ''}"
                                                        @click="updateEntry('communication_contact_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('communication_contact_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('communication_contact_type_option')" v-if="phone_type_options.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="communication_contact_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-5" v-if="profile_section_selected == 'Health'">
                                <div class="text-center">
                                    <img class="mt-15" src="/static/images/data_empty.svg" />
                                    <h1 class="fs-14 fw-600 mb-1 mt-10"
                                        style="letter-spacing: 0.34px;text-align: center;color: #707070;">No options to
                                        configure. All health options are listed using the predefined medical API'S.
                                    </h1>
                                    <p class="fs-14 fw-500"
                                        style="letter-spacing: 0.34px;text-align: center;color: #707070;">Please contact
                                        System Administrator for more assistance.</p>
                                </div>
                            </div>
                            <div class="pt-5" v-if="profile_section_selected == 'Preferences'">
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Dietary Preference Options</h1>
                                    <div>
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && dietary_data.length == 0">
                                            <span>Dietary Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(dietary, dietary_index) in dietary_data" :key="dietary_index+'dietary_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="dietaryTypeCheckbox($event, dietary.id)" :name="'dietaryess_id'+dietary_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="dietary.value" class="pl-3 fs-14 text-truncate">{{dietary.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_dietary_preference_type"
                                            class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_dietary_preference_type"
                                                    placeholder="Enter Dietary Preference" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_dietary_preference_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer"
                                                        @click="removeEntry('dietary_preference_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" :class="{'disabled' : new_dietary_preference_type == ''}"
                                                        @click="updateEntry('dietary_preference_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('dietary_preference_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('dietary_preference_type_option')" v-if="dietary_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="dietary_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Room Type Options</h1>
                                    <div class="">
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && room_data.length == 0">
                                            <span>Room Types Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(room, room_index) in room_data" :key="room_index+'room_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="roomTypeCheckbox($event, room.id)" :name="'roomess_id1'+room_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="room.value" class="pl-3 fs-14 text-truncate">{{room.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_room_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_room_type" placeholder="Enter Room Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_room_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('room_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateEntry('room_type_option')" :class="{'disabled' : new_room_type == ''}">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('room_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('room_type_option')" v-if="room_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="room_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Bed Type Options</h1>
                                    <div class="">
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && bed_data.length == 0">
                                            <span>Bed Types Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(bed, bed_index) in bed_data" :key="bed_index+'bed_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="bedTypeCheckbox($event, bed.id)" :name="'bedess_id1'+bed_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="bed.value" class="pl-3 fs-14 text-truncate">{{bed.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_bed_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_bed_type" placeholder="Enter Bed Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_bed_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('bed_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateEntry('bed_type_option')" :class="{'disabled' : new_bed_type == ''}">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('bed_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('bed_type_option')" class="btn btn-new-danger mt-4" v-if="bed_data.length > 0" :disabled="bed_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Pillow Type Options</h1>
                                    <div class="">
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && pillow_data.length == 0">
                                            <span>Pillow Types Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(pillow, pillow_index) in pillow_data" :key="pillow_index+'pillow_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="pillowTypeCheckbox($event, pillow.id)" :name="'pillowess_id1'+pillow_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="pillow.value" class="pl-3 fs-14 text-truncate">{{pillow.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_pillow_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_pillow_type" placeholder="Enter Pillow Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_pillow_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('pillow_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" :class="{'disabled' : new_pillow_type == ''}"
                                                        @click="updateEntry('pillow_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('pillow_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('pillow_type_option')" v-if="pillow_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="pillow_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Bedsheet Type Options</h1>
                                    <div class="">
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && bedsheet_data.length == 0">
                                            <span>Bedsheet Types Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(bedsheet, bedsheet_index) in bedsheet_data" :key="bedsheet_index+'bedsheet_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="bedSheetTypeCheckbox($event, bedsheet.id)" :name="'bedsheetess_id1'+bedsheet_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="bedsheet.value" class="pl-3 fs-14 text-truncate">{{bedsheet.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_bedsheet_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_bedsheet_type" placeholder="Enter Bedsheet Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_bedsheet_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('bedsheet_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" :class="{'disabled' : new_bedsheet_type == ''}"
                                                        @click="updateEntry('bedsheet_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('bedsheet_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('bedsheet_type_option')" v-if="bedsheet_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="bedsheet_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-5" v-if="profile_section_selected == 'Reminders'">
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Relationships Options</h1>
                                    <div class="">
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && relationship_data.length == 0">
                                            <span>Relationships Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(relationship, relationship_index) in relationship_data" :key="relationship_index+'relationship_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="relationShipTypeCheckbox($event, relationship.id)" :name="'relationshipess_id1'+relationship_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="relationship.value" class="pl-3 fs-14 text-truncate">{{relationship.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_relationship_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_relationship_type"
                                                    placeholder="Enter Relationship Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_relationship_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer"
                                                        @click="removeEntry('relationship_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" :class="{'disabled' : new_relationship_type == ''}"
                                                        @click="updateEntry('relationship_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('relationship_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('relationship_type_option')" v-if="relationship_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="relationship_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <hr>
                                <div class="pt-2 pb-4">
                                    <h1 class="text-secondary fs-14 md-5">Event Options</h1>
                                    <div class="">
                                        <div v-if="profile_ajax_call_in_progress" class="d-flex align-items-center justify-content-center w-100">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center w-100" v-else-if="!profile_ajax_call_in_progress && event_data.length == 0">
                                            <span>Relationships Not Found</span>
                                        </div>
                                        <div v-if="!profile_ajax_call_in_progress">
                                            <div class="row w-100" v-if="!profile_loader_ajax">
                                                <div v-for="(event, event_index) in event_data" :key="event_index+'event_index'" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                                    <div class="d-flex align-items-center w-100">
                                                        <el-checkbox class="mb-0" @change="eventTypeCheckbox($event, event.id)" :name="'eventess_id1'+event_index"></el-checkbox>
                                                        <div class="input-field d-flex align-items-center w-100 ml-4">
                                                            <span :title="event.value" class="pl-3 fs-14 text-truncate">{{event.value}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="add_new_event_type" class="col-lg-6 col-md-12 col-sm-12 pt-5">
                                            <div class="d-flex align-items-center w-100 position-relative">
                                                <el-checkbox class="mb-0" :name="'industry_type_id1'"></el-checkbox>
                                                <input class="input-field w-100 ml-4"
                                                    style="padding-left: 21px !important;" maxlength="100" type="text"
                                                    v-model="new_event_type" placeholder="Enter Event Type" />
                                                <span style="position: absolute;top: 40px;right: 0px;">
                                                    {{100 - (new_event_type).length}}/100
                                                </span>
                                                <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="bottom: auto; top: auto;right: -50px;">
                                                    <div class="pointer" @click="removeEntry('event_type_option')">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                    </div>
                                                    <div class="ml-2 pointer" @click="updateEntry('event_type_option')" :class="{'disabled' : new_event_type == ''}">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-10 mr-8 text-right">
                                        <button @click="addNew('event_type_option')"
                                            class="text-secondary fs-14 pointer text-right d-block ml-auto"
                                            style="background-color: transparent; outline: 0 none !important;">+ Add New
                                            Field</button>
                                        <button @click="deleteInput('event_type_option')" v-if="event_data.length > 0"
                                            class="btn btn-new-danger mt-4" :disabled="event_type_selected.length<1">
                                            <i class="icon icon-minus fs-14"></i>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <change-password v-if="change_password" modal_name="change_password_modal" @passwordChanged="passwordChanged"></change-password>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <password-changed v-if="password_changed" modal_name="password_changed_modal"></password-changed>
    </div>
</template>
<script>
    import settings from '../mixins/settings';
    import ChangePassword from './ChangePassword.vue';
    import timezone from '../json/timezone.json';
    import languages from '../json/language.json';
    import {HalfCircleSpinner} from 'epic-spinners';
    import { SweetModal } from 'sweet-modal-vue';
    import organization from '../mixins/organization';
    import PasswordChanged from './PasswordChanged.vue';
    export default {
        components: { ChangePassword, SweetModal, HalfCircleSpinner, PasswordChanged },
        mixins: [settings, organization],
        data() {
            return {
                general_active: true,
                profile_active: false,
                two_step_enable: false,
                ssn_checked: false,
                ein_checked: false,
                phone_type_checked: false,
                time_zone: '',
                time_zone_options: timezone,
                language: '',
                language_options: languages,
                warning_msg: '',
                success_msg: '',
                profile_section_selected: 'Address',
                profile_section_options: ['Address', 'Communication', 'Health', 'Preferences', 'Reminders'],
                // prfile_section_options: ['Address'],
                industryTab: true,
                contactTab: true,
                accountTab: true,
                phone_type_options: [{
                        name: 'Home',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg'
                    },
                    {
                        name: 'Mobile',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                    },
                    {
                        name: 'Whatsapp',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                    },
                    {
                        name: 'Line',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                    },
                    {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    {
                        name: 'Telegram',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                    },
                    {
                        name: 'Others',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                    },
                    {
                        name: 'Messenger',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                    },
                    {
                        name: 'Fax',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                    }
                ],
                add_new_taxid_type: false,
                new_taxid_type: '',
                add_new_address_type: false,
                new_address_type: '',
                new_contact_type: '',
                new_industry_type: '',
                add_new_communication_contact_type: false,
                new_communication_contact_type: '',
                add_new_dietary_preference_type: false,
                new_dietary_preference_type: '',
                add_new_room_type: false,
                new_room_type: '',
                add_new_bed_type: false,
                new_bed_type: '',
                add_new_pillow_type: false,
                new_pillow_type: '',
                add_new_bedsheet_type: false,
                new_bedsheet_type: '',
                add_new_relationship_type: false,
                new_relationship_type: '',
                add_new_event_type: false,
                new_event_type: '',
                add_new_contact_type: false,
                add_new_industry_type: false,
                ajax_call_in_progress: false,
                profile_ajax_call_in_progress: false,
                settings_data: {},
                industry_type_data: [],
                contact_type_data: [],
                change_password: false,
                password_changed: false,
                contact_type_selected: [],
                industry_type_selected: [],
                adddress_data: [],
                communication_data: [],
                event_data: [],
                dietary_data: [],
                bed_data: [],
                pillow_data: [],
                bedsheet_data: [],
                relationship_data: [],
                room_data: [],
                address_type_selected: [],
                contact_type_selected: [],
                communication_contact_type_selected: [],
                dietary_type_selected: [],
                room_type_selected: [],
                bed_type_selected: [],
                pillow_type_selected: [],
                bedsheet_type_selected: [],
                relationship_type_selected: [],
                event_type_selected: [],
                organization_options: [],
                organization: {},
                organization_comp: [],
                // list_view_data: [],
                org_loading: false,
                skip: 0,
                limit: 0,
                search_key: '',
                selected_organization_id: '',
                selected_company_id: '',
                selected_comp_data: {},
                res_data: {},
                is_otp_login: false,
                loader_data_ajax: false,
                profile_loader_ajax: false
            }
        },
        methods: {
            selectTab(tab) {
                if (tab == 'general') {
                    this.$router.push('/settings/general');
                } else if (tab == 'profile') {
                    this.$router.push('/settings/profile');
                }
            },
            changeOrg(selected) {
                this.organization = selected;
                this.selected_organization_id = selected.id;
                this.organization_comp = selected.company_list;
                this.organization_comp.forEach((comp, index) => {
                    if(comp.company_id === this.selected_comp_data.company_id) {
                        comp.checked = true;
                    } else {
                        comp.checked = false;
                    }
                });
            },
            changeComp(data) {
                this.selected_company_id = data.company_id;
                this.selected_organization_id = this.organization.id
                this.updateSettings('switch_company');
            },
            async getListViewData() {
                this.organization_options = [];
                this.org_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        search_key: this.search_key
                    }
                    let response = await this.getHierarchyListView(params);
                    if(response.status_id == 1) {
                        this.res_data = response;
                        this.organization_options = this.res_data.response;
                        if(this.res_data.value_type == 'manage_organization') {
                            let org_data = this.organization_options.filter(org => org.id === this.organization.id)
                            this.organization_comp = org_data[0].company_list;
                            this.organization_comp.forEach((comp, index) => {
                                if(comp.company_id === this.selected_comp_data.company_id) {
                                    comp.checked = true;
                                } else {
                                    comp.checked = false; 
                                }
                            });
                        }
                    }
                    this.org_loading = false;
                } catch(err) {
                    this.org_loading = false;
                }
            },
            showManageAccount(){
                if (this.accountTab) {
                    this.accountTab = false;
                } else {
                    this.accountTab = true;
                }
            },
            showContactInformation() {
                if (this.contactTab) {
                    this.contactTab = false;
                } else {
                    this.contactTab = true;
                }
            },
            showIndustryInformation() {
                if (this.industryTab) {
                    this.industryTab = false;
                } else {
                    this.industryTab = true;
                }
            },
            changePassword() {
                this.change_password = true;
                setTimeout(() => {
                    this.$modal.show("change_password_modal");
                }, 500);
            },
            passwordChanged() {
                this.change_password = false;
                this.password_changed = true;
                setTimeout(() => {
                    this.$modal.show("password_changed_modal");
                }, 500);
            },
            addNew(type) {
                if (type == 'industry_type_option') {
                    if(this.add_new_industry_type) {
                        this.warning_msg = 'Please Save In-Progress Industry Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_industry_type = true;
                } else if (type == 'contact_type_option') {
                    if(this.add_new_contact_type) {
                        this.warning_msg = 'Please Save In-Progress Contact Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_contact_type = true;
                } else if (type == 'address_type_option') {
                    if(this.add_new_address_type) {
                        this.warning_msg = 'Please Save In-Progress Address Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_address_type = true;
                } else if (type == 'communication_contact_type_option') {
                    if(this.add_new_communication_contact_type) {
                        this.warning_msg = 'Please Save In-Progress Phone Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_communication_contact_type = true;
                } else if (type == 'dietary_preference_type_option') {
                    if(this.add_new_dietary_preference_type) {
                        this.warning_msg = 'Please Save In-Progress Dietary Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_dietary_preference_type = true;
                } else if (type == 'room_type_option') {
                    if(this.add_new_room_type) {
                        this.warning_msg = 'Please Save In-Progress Room Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_room_type = true;
                } else if (type == 'bed_type_option') {
                    if(this.add_new_bed_type) {
                        this.warning_msg = 'Please Save In-Progress Bed Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_bed_type = true;
                } else if (type == 'pillow_type_option') {
                    if(this.add_new_pillow_type) {
                        this.warning_msg = 'Please Save In-Progress Pillow Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_pillow_type = true;
                } else if (type == 'bedsheet_type_option') {
                    if(this.add_new_bedsheet_type) {
                        this.warning_msg = 'Please Save In-Progress Bedsheet Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_bedsheet_type = true;
                } else if (type == 'relationship_type_option') {
                    if(this.add_new_relationship_type) {
                        this.warning_msg = 'Please Save In-Progress Relationship Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_relationship_type = true;
                } else if (type == 'event_type_option') {
                    if(this.add_new_event_type) {
                        this.warning_msg = 'Please Save In-Progress Event Type.'
                        this.$refs.warning_modal.open();
                    }
                    this.add_new_event_type = true;
                }
            },
            removeEntry(type) {
                if (type == 'industry_type_option') {
                    this.add_new_industry_type = false;
                    this.new_industry_type = '';
                } else if (type == 'contact_type_option') {
                    this.add_new_contact_type = false;
                    this.new_contact_type = '';
                } else if (type == 'address_type_option') {
                    this.add_new_address_type = false;
                    this.new_address_type = '';
                } else if (type == 'communication_contact_type_option') {
                    this.add_new_communication_contact_type = false;
                    this.new_communication_contact_type = '';
                } else if (type == 'dietary_preference_type_option') {
                    this.add_new_dietary_preference_type = false;
                    this.new_dietary_preference_type = '';
                } else if (type == 'room_type_option') {
                    this.add_new_room_type = false;
                    this.new_room_type = '';
                } else if (type == 'bed_type_option') {
                    this.add_new_bed_type = false;
                    this.new_bed_type = '';
                } else if (type == 'pillow_type_option') {
                    this.add_new_pillow_type = false;
                    this.new_pillow_type = '';
                } else if (type == 'bedsheet_type_option') {
                    this.add_new_bedsheet_type = false;
                    this.new_bedsheet_type = '';
                } else if (type == 'relationship_type_option') {
                    this.add_new_relationship_type = false;
                    this.new_relationship_type = '';
                } else if (type == 'event_type_option') {
                    this.add_new_event_type = false;
                    this.new_event_type = '';
                }
            },
            updateEntry(type) {
                if (type == 'industry_type_option') {
                    this.addNewSettingType(this.new_industry_type, 'industry_type');
                } else if (type == 'contact_type_option') {
                    this.addNewSettingType(this.new_contact_type, 'contact_type');
                } else if (type == 'address_type_option') {
                    this.addNewProfileSettingType(this.new_address_type, 'address', 'Address added successfully.');
                } else if (type == 'communication_contact_type_option') {
                    this.addNewProfileSettingType(this.new_communication_contact_type, 'communication', 'Contact Option added successfully.');
                } else if (type == 'dietary_preference_type_option') {
                    this.addNewProfileSettingType(this.new_dietary_preference_type, 'dietary', 'Dietary Option added successfully.');
                } else if (type == 'room_type_option') {
                    this.addNewProfileSettingType(this.new_room_type, 'room', 'Room Type added successfully.');
                } else if (type == 'bed_type_option') {
                    this.addNewProfileSettingType(this.new_bed_type, 'bed', 'Bed Type added successfully.');
                } else if (type == 'pillow_type_option') {
                    this.addNewProfileSettingType(this.new_pillow_type, 'pillow', 'Pillow Type added successfully.');
                } else if (type == 'bedsheet_type_option') {
                    this.addNewProfileSettingType(this.new_bedsheet_type, 'bedsheet', 'Bedsheet Type added successfully.');
                } else if (type == 'relationship_type_option') {
                    this.addNewProfileSettingType(this.new_relationship_type, 'relationship', 'Relationship Type added successfully.');
                } else if (type == 'event_type_option') {
                    this.addNewProfileSettingType(this.new_event_type, 'event', 'Event Type added successfully.');
                }
            },
            deleteInput(type) {
                if (type == 'industry_type_option') {
                    this.deleteSettingType(this.industry_type_selected, 'industry_type');
                } else if (type == 'contact_type_option') {
                    this.deleteSettingType(this.contact_type_selected, 'contact_type');
                } else if (type == 'address_type_option') {
                    this.deleteProfileSettingType(this.address_type_selected, 'address');
                } else if (type == 'communication_contact_type_option') {
                    this.deleteProfileSettingType(this.communication_contact_type_selected, 'communication');
                } else if (type == 'dietary_preference_type_option') {
                    this.deleteProfileSettingType(this.dietary_type_selected, 'dietary');
                } else if (type == 'room_type_option') {
                    this.deleteProfileSettingType(this.room_type_selected, 'room');
                } else if (type == 'bed_type_option') {
                    this.deleteProfileSettingType(this.bed_type_selected, 'bed');
                } else if (type == 'pillow_type_option') {
                    this.deleteProfileSettingType(this.pillow_type_selected, 'pillow');
                } else if (type == 'bedsheet_type_option') {
                    this.deleteProfileSettingType(this.bedsheet_type_selected, 'bedsheet');
                } else if (type == 'relationship_type_option') {
                    this.deleteProfileSettingType(this.relationship_type_selected, 'relationship');
                } else if (type == 'event_type_option') {
                    this.deleteProfileSettingType(this.event_type_selected, 'event');
                }
            },

            /***************************************************** General Settings code  ********************************************/
            contactTypeCheckbox(val, id) {
                if(val === true) {
                    this.contact_type_selected.push(id);
                } else {
                    let index = this.contact_type_selected.indexOf(id);
                    if (index > -1) {
                        this.contact_type_selected.splice(index, 1);
                    }
                }
            },
            industryTypeCheckbox(val, id) {
                if(val === true) {
                    this.industry_type_selected.push(id);
                } else {
                    let index = this.industry_type_selected.indexOf(id);
                    if (index > -1) {
                        this.industry_type_selected.splice(index, 1);
                    }
                }
            },
            async getGeneralSettingById(id) {
                this.contact_type_selected = [];
                this.industry_type_selected = [];
                this.add_new_industry_type = false;
                this.add_new_contact_type = false;
                this.new_contact_type = '';
                this.new_industry_type = '';
                this.loader_data_ajax = true;
                // this.ajax_call_in_progress = true;
                try {
                    let response = await this.getListGeneralSettings(id);
                    this.loader_data_ajax = false;
                    if (response.status_id == 1) {
                        this.settings_data = response.response;
                        this.contact_type_data = this.settings_data.contact_type;
                        this.industry_type_data = this.settings_data.industry_type;
                        this.two_step_enable = this.settings_data.is_two_step_verification;
                        this.is_otp_login = this.settings_data.is_otp_login
                        this.time_zone = {
                            label: '',
                            value: this.settings_data.time_zone || ''
                        };
                        this.organization = {
                            organization_name: this.settings_data.organisation_name,
                            id: this.settings_data.organisation_id
                        };
                        this.selected_comp_data = {
                            company_id:  this.settings_data.company_id,
                            company_name:  this.settings_data.company_name,
                        }
                        // this.organization_comp = this.settings_data.company_name
                        this.language = this.settings_data.language;
                        setTimeout(() => {
                            // this.getListViewData();
                        }, 500);
                    }
                    this.loader_data_ajax = false;
                    // this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.loader_data_ajax = false;
                    // this.ajax_call_in_progress = false;
                }
            },
            async getGeneralSettingByIdFirst(id) {
                this.contact_type_selected = [];
                this.industry_type_selected = [];
                this.add_new_industry_type = false;
                this.add_new_contact_type = false;
                this.new_contact_type = '';
                this.new_industry_type = '';
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getListGeneralSettings(id);
                    if (response.status_id == 1) {
                        this.settings_data = response.response;
                        console.log(this.settings_data)
                        console.log("Hello")
                        this.contact_type_data = this.settings_data.contact_type;
                        this.industry_type_data = this.settings_data.industry_type;
                        this.two_step_enable = this.settings_data.is_two_step_verification;
                        this.is_otp_login = this.settings_data.is_otp_login
                        this.time_zone = {
                            label: '',
                            value: this.settings_data.time_zone || ''
                        };
                        this.organization = {
                            organization_name: this.settings_data.organisation_name,
                            id: this.settings_data.organisation_id
                        };
                        this.selected_comp_data = {
                            company_id:  this.settings_data.company_id,
                            company_name:  this.settings_data.company_name,
                        }
                        // this.organization_comp = this.settings_data.company_name
                        this.language = this.settings_data.language;
                        setTimeout(() => {
                            this.getListViewData();
                        }, 500);
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            },
            changeTwoFactoreAuth(val) {
                this.updateSettings('two_step');
            },
            changeLoginAuth(val) {
                this.updateSettings('login_step');
            },
            changeTimeZone(val) {
                this.time_zone = val;
                if(Object.keys(val).length) {
                    this.updateSettings('time_zone');
                }
            },
            changeLanguage(val) {
                if(val) {
                    this.language = val;
                    this.updateSettings('langauge');
                }
            },
            async deleteSettingType(id, type) {
                try {
                    let params = {
                        id: id,
                        type: type
                    };
                    let response = await this.deleteGeneralSettings(params);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.industry_type_selected = []
                            this.contact_type_selected = []
                            this.$refs.success_modal.close();
                            this.getGeneralSettingById(this.loggedInUser.id);
                        }, 2000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            async addNewSettingType(value, type) {
                try {
                    let params = {
                        value: value,
                        type: type,
                        company_id: this.loggedInUser.company_id
                    };
                    let response = await this.addGeneralSettings(params);
                    if(response.status_id == 1) {
                        if(type == 'contact_type') {
                            this.success_msg = 'Contact type added successfully.'
                        } else if(type == 'industry_type') {
                            this.success_msg = 'Industry type added successfully.'
                        } else {
                            this.success_msg = response.message
                        }
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getGeneralSettingById(this.loggedInUser.id);
                        }, 2000);
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            async updateSettings(type) {
                try {
                    let params = {
                        user_id: this.user_id
                    };
                    if(type == 'time_zone') {
                        params.time_zone = this.time_zone.value;
                    } else if (type == 'langauge') {
                        params.language = this.language;
                    } else if(type == 'two_step') {
                        params.is_two_step_verification = this.two_step_enable;
                    }else if(type == 'login_step') {
                        params.is_otp_login = this.is_otp_login;
                    }else if(type == 'switch_company') {
                        params.organisation_id = this.selected_organization_id;
                        params.company_id = this.selected_company_id;
                    }
                    let response = await this.updateGeneralSettings(params, this.loggedInUser.id);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getGeneralSettingById(this.loggedInUser.id);
                        }, 2000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },

            /***************************************************** Profile Settings code  ********************************************/
            addressTypeCheckbox(val, id) {
                if(val === true) {
                    this.address_type_selected.push(id);
                } else {
                    let index = this.address_type_selected.indexOf(id);
                    if (index > -1) {
                        this.address_type_selected.splice(index, 1);
                    }
                }
            },
            communicationContactTypeCheckbox(val, id) {
                if(val === true) {
                    this.communication_contact_type_selected.push(id);
                } else {
                    let index = this.communication_contact_type_selected.indexOf(id);
                    if (index > -1) {
                        this.communication_contact_type_selected.splice(index, 1);
                    }
                }
            },
            dietaryTypeCheckbox(val, id) {
                if(val === true) {
                    this.dietary_type_selected.push(id);
                } else {
                    let index = this.dietary_type_selected.indexOf(id);
                    if (index > -1) {
                        this.dietary_type_selected.splice(index, 1);
                    }
                }
            },
            roomTypeCheckbox(val, id) {
                if(val === true) {
                    this.room_type_selected.push(id);
                } else {
                    let index = this.room_type_selected.indexOf(id);
                    if (index > -1) {
                        this.room_type_selected.splice(index, 1);
                    }
                }
            },
            bedTypeCheckbox(val, id) {
                if(val === true) {
                    this.bed_type_selected.push(id);
                } else {
                    let index = this.bed_type_selected.indexOf(id);
                    if (index > -1) {
                        this.bed_type_selected.splice(index, 1);
                    }
                }
            },
            pillowTypeCheckbox(val, id) {
                if(val === true) {
                    this.pillow_type_selected.push(id);
                } else {
                    let index = this.pillow_type_selected.indexOf(id);
                    if (index > -1) {
                        this.pillow_type_selected.splice(index, 1);
                    }
                }
            },
            bedSheetTypeCheckbox(val, id) {
                if(val === true) {
                    this.bedsheet_type_selected.push(id);
                } else {
                    let index = this.bedsheet_type_selected.indexOf(id);
                    if (index > -1) {
                        this.bedsheet_type_selected.splice(index, 1);
                    }
                }
            },
            relationShipTypeCheckbox(val, id) {
                if(val === true) {
                    this.relationship_type_selected.push(id);
                } else {
                    let index = this.relationship_type_selected.indexOf(id);
                    if (index > -1) {
                        this.relationship_type_selected.splice(index, 1);
                    }
                }
            },
            eventTypeCheckbox(val, id) {
                if(val === true) {
                    this.event_type_selected.push(id);
                } else {
                    let index = this.event_type_selected.indexOf(id);
                    if (index > -1) {
                        this.event_type_selected.splice(index, 1);
                    }
                }
            },
            profileSetionSelected(selected_option) {
                if(selected_option == 'Address') {
                    this.getProfileSettingByType('address');
                } else if(selected_option == 'Communication') {
                    this.getProfileSettingByType('communication');
                } else if(selected_option == 'Preferences') {
                    this.getProfileSettingByType('dietary');
                    this.getProfileSettingByType('bed');
                    this.getProfileSettingByType('pillow');
                    this.getProfileSettingByType('bedsheet');
                    this.getProfileSettingByType('room');
                } else if(selected_option == 'Reminders') {
                    this.getProfileSettingByType('event');
                    this.getProfileSettingByType('relationship');
                }
            },
            async getProfileSettingByTypeFirst(type) {
                this.add_new_address_type = false;
                this.add_new_dietary_preference_type = false;
                this.add_new_communication_contact_type = false;
                this.add_new_room_type = false;
                this.add_new_bed_type = false;
                this.add_new_pillow_type = false;
                this.add_new_bedsheet_type = false;
                this.add_new_relationship_type = false;
                this.add_new_event_type = false;
                this.address_type_selected = [];
                this.dietary_type_selected = [];
                this.communication_contact_type_selected = [];
                this.room_type_selected = [];
                this.bed_type_selected = [];
                this.pillow_type_selected = [];
                this.bedsheet_type_selected = [];
                this.relationship_type_selected = [];
                this.event_type_selected = [];
                this.new_address_type = '';
                this.new_dietary_preference_type = '';
                this.new_communication_contact_type = '';
                this.new_room_type = '';
                this.new_bed_type = '';
                this.new_pillow_type = '';
                this.new_bedsheet_type = '';
                this.new_relationship_type = '';
                this.new_event_type = '';
                this.profile_loader_ajax = true
                // this.profile_ajax_call_in_progress = true;
                try {
                    let params = {
                        type: type
                    }
                    let response = await this.getListSettings(params);
                    this.profile_loader_ajax = false
                    if (response.status_id == 1) {
                        if(type == 'address') {
                            this.adddress_data = response.response.address;
                        } else if(type == 'communication') {
                            this.phone_type_options = response.response.communication;
                        } else if(type == 'event') {
                            this.event_data = response.response.event;
                        } else if(type == 'dietary') {
                            this.dietary_data = response.response.dietary;
                        } else if(type == 'bed') {
                            this.bed_data = response.response.bed;
                        } else if(type == 'pillow') {
                            this.pillow_data = response.response.pillow;
                        } else if(type == 'bedsheet') {
                            this.bedsheet_data = response.response.bedsheet;
                        } else if(type == 'relationship') {
                            this.relationship_data = response.response.relationship;
                        } else if(type == 'room') {
                            this.room_data = response.response.room;
                        }
                    }
                    this.profile_loader_ajax = false
                    // this.profile_ajax_call_in_progress = false;
                }
                catch(err) {
                    this.profile_loader_ajax = false
                    // this.profile_ajax_call_in_progress = false;
                }
            },
            async getProfileSettingByType(type) {
                this.add_new_address_type = false;
                this.add_new_dietary_preference_type = false;
                this.add_new_communication_contact_type = false;
                this.add_new_room_type = false;
                this.add_new_bed_type = false;
                this.add_new_pillow_type = false;
                this.add_new_bedsheet_type = false;
                this.add_new_relationship_type = false;
                this.add_new_event_type = false;
                this.address_type_selected = [];
                this.dietary_type_selected = [];
                this.communication_contact_type_selected = [];
                this.room_type_selected = [];
                this.bed_type_selected = [];
                this.pillow_type_selected = [];
                this.bedsheet_type_selected = [];
                this.relationship_type_selected = [];
                this.event_type_selected = [];
                this.new_address_type = '';
                this.new_dietary_preference_type = '';
                this.new_communication_contact_type = '';
                this.new_room_type = '';
                this.new_bed_type = '';
                this.new_pillow_type = '';
                this.new_bedsheet_type = '';
                this.new_relationship_type = '';
                this.new_event_type = '';
                this.profile_ajax_call_in_progress = true;
                try {
                    let params = {
                        type: type
                    }
                    let response = await this.getListSettings(params);
                    if (response.status_id == 1) {
                        if(type == 'address') {
                            this.adddress_data = response.response.address;
                        } else if(type == 'communication') {
                            this.phone_type_options = response.response.communication;
                        } else if(type == 'event') {
                            this.event_data = response.response.event;
                        } else if(type == 'dietary') {
                            this.dietary_data = response.response.dietary;
                        } else if(type == 'bed') {
                            this.bed_data = response.response.bed;
                        } else if(type == 'pillow') {
                            this.pillow_data = response.response.pillow;
                        } else if(type == 'bedsheet') {
                            this.bedsheet_data = response.response.bedsheet;
                        } else if(type == 'relationship') {
                            this.relationship_data = response.response.relationship;
                        } else if(type == 'room') {
                            this.room_data = response.response.room;
                        }
                    }
                    this.profile_ajax_call_in_progress = false;
                }
                catch(err) {
                    this.profile_ajax_call_in_progress = false;
                }
            },
            async deleteProfileSettingType(id, type) {
                try {
                    let params = {
                        id: id,
                        type: type
                    };
                    let response = await this.deleteSettings(params);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.address_type_selected = []
                            this.communication_contact_type_selected = []
                            this.dietary_type_selected = []
                            this.room_type_selected = []
                            this.bed_type_selected = []
                            this.pillow_type_selected = []
                            this.bedsheet_type_selected = []
                            this.relationship_type_selected = []
                            this.event_type_selected = []
                            this.communicationContactTypeCheckbox(false, "")
                            console.log("dhufiehe")
                            console.log(this.communication_contact_type_selected)
                            this.$refs.success_modal.close();
                            this.getProfileSettingByTypeFirst(type);
                        }, 2000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            async addNewProfileSettingType(value, type, message) {
                try {
                    let params = {
                        value: value,
                        type: type,
                        company_id: this.loggedInUser.company_id
                    };
                    let response = await this.addSettings(params);
                    if(response.status_id == 1) {
                        this.success_msg = message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getProfileSettingByTypeFirst(type);
                        }, 2000);
                    }
                }
                catch(err) {
                    // console.log(err);
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
        },
        mounted() {
            if(this.$route.path == '/settings/general') {
                this.getGeneralSettingByIdFirst(this.loggedInUser.id);
            } else if(this.$route.path == '/settings/profile') {
                this.getProfileSettingByType('address');
            }
            if(this.$route.query.reminders) {
                this.profile_section_selected = "Reminders";
                this.getProfileSettingByType('event');
                this.getProfileSettingByType('relationship');
            }
            let tz = new Date().getTimezoneOffset().toString();
            if (tz == "-330") {
                this.time_zone = {
                    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)",
                    value: "Asia/Kolkata",
                };
            } else if (tz == "-420") {
                this.time_zone = {
                    label: "(GMT+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)",
                    value: "Asia/Bangkok",
                };
            }
        },
        computed: {
			loggedInUser() {
				return this.$store.state.loggedInUser;
			},
			// userAccounts() {
			// 	return this.$store.state.userAccounts;
			// },
			// isBusinessAccount() {
			// 	return this.$store.state.isBusinessAccount;
			// },
			// account() {
			// 	return this.$store.state.account;
			// },
			// selectedCompany() {
			// 	return this.$store.state.selectedCompany;
			// }
		},
        watch: {
            "$route.path": function(path) {
                if(path == '/settings/general') {
                    this.getGeneralSettingByIdFirst(this.loggedInUser.id);
                } else if(this.$route.path == '/settings/profile') {
                    this.getProfileSettingByType('address');
                }
            }
        }
    }
</script>
<style scoped>
    .settings-container {
        padding: 0.4px 0.2px 0px 0;
        border-radius: 6px;
        background-color: #fff;
        min-height: 49em;
        height: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .settings-left-sec {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        width: 250px !important;
        min-width: 250px !important;
        max-width: 250px !important;
        box-shadow: 0 0 10px 0 #eaeaea;
    }

    .settings-left-sec-list {
        padding-top: 5px;
        padding-left: 0;
    }

    .settings-left-sec-list li {
        list-style: none;
        margin: 10px 0;
        padding: 8px 20px;
        border-radius: 2.5px;
        letter-spacing: 0.43px;
        color: #3f3f3f;
    }

    .settings-left-sec-list li.activeOption {
        color: #00448b;
        background-color: #ecf1f9;
    }

    .create-settings-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .create-settings-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    .account-box {
        padding: 10px;
        border-radius: 4px;
        border: solid 0.5px #707070;
        background-color: var(--white);
    }
    .account-box-disabled{
        padding: 10px;
        border-radius: 4px;
        border: solid 0.5px #afafaf !important;
        background-color: var(--white);
    }
    .disabled-text{
        color: #afafaf !important;
    }
    .disabled{
        cursor: no-drop !important;
        pointer-events: none !important;
    }
</style>